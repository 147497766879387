<template>
  <el-main>
    <el-button style="margin-bottom: 10px" type="primary" size="small" @click="addNewGroupMsg">新建群发</el-button>
    <!-- 查询 -->
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="任务名称：">
        <el-input size="small" v-model="search.taskName" placeholder="请输入任务名称"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker v-model="search.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
          <el-button class="searchBtn" type="primary" size="small" @click="getList(1)">搜索</el-button>
      <el-button class="searchBtn" type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="msgList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="任务名称" align="center"></el-table-column>
      <el-table-column label="群发类型" align="center">
        <template v-slot="{ row }">
          {{ row.chat_type === 'single' ? '群发到客户' : '群发到群' }}
        </template>
      </el-table-column>
      <el-table-column label="发送类型" align="center">
        <template v-slot="{ row }">
          {{ row.send_type === 1 ? '立即发送' : '定时发送' }}
        </template>
      </el-table-column>
      <el-table-column label="定时发送时间" align="center">
        <template v-slot="{ row }">
          {{ row.send_type === 2 ? getDateformat(row.send_time) : '--' }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="lookDetail(row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      msgList: [],
      search: {
        taskName: '',
        time: [],
      },
      page: 1,
      rows: 10,
      total: 0,
      getDateformat,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 分页操作
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    // 获取发送的消息的列表
    getList(status) {
      if (status === 1) {
        this.page = 1;
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      let nowRouter = this.$route.path;
      obj.chat_type = nowRouter === '/user/enterpriseWechat/messagePerson' ? 'single' : 'group';
      if (this.search.taskName) {
        obj.name = this.search.taskName;
      }
      if (this.search.time.length > 0) {
        obj.start_time = Math.floor(this.search.time[0].getTime() / 1000);
        obj.end_time = Math.floor(this.search.time[1].getTime() / 1000);
        if (obj.start_time === obj.end_time) {
          obj.end_time += 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.user.WxworkMessageList, obj).then(res => {
        if (res.code === 0) {
          this.msgList = res.result.list;
          this.total = res.result.total_number;
        }
      });
    },
    // 区分不同路由下跳转不同的添加路由
    addNewGroupMsg() {
      let nowRouter = this.$route.path;
      let path = nowRouter === '/user/enterpriseWechat/messagePerson' ? '/user/messagePerson/add' : '/user/messageTeam/add';
      this.$router.push(path);
    },
    // 点击跳转到消息详情页面 -->区分路由是为了保证菜单高亮正确
    lookDetail(item) {
      let nowRouter = this.$route.path;
      let path = nowRouter === '/user/enterpriseWechat/messagePerson' ? '/user/messagePerson/messageDetail' : '/user/messageTeam/messageDetail';
      this.$router.push({
        path,
        query: item,
      });
    },
    // 清空搜索条件
    clearSearch() {
      this.search = {
        taskName: '',
        time: [],
      };
      this.getList(1);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
