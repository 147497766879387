var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.addNewGroupMsg}},[_vm._v("新建群发")]),_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"任务名称："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入任务名称"},model:{value:(_vm.search.taskName),callback:function ($$v) {_vm.$set(_vm.search, "taskName", $$v)},expression:"search.taskName"}})],1),_c('el-form-item',{attrs:{"label":"创建时间："}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","size":"small"},model:{value:(_vm.search.time),callback:function ($$v) {_vm.$set(_vm.search, "time", $$v)},expression:"search.time"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"25px"}},[_c('el-button',{staticClass:"searchBtn",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")]),_c('el-button',{staticClass:"searchBtn",attrs:{"type":"text","size":"small"},on:{"click":_vm.clearSearch}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.msgList,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"name","label":"任务名称","align":"center"}}),_c('el-table-column',{attrs:{"label":"群发类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.chat_type === 'single' ? '群发到客户' : '群发到群')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"发送类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.send_type === 1 ? '立即发送' : '定时发送')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"定时发送时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.send_type === 2 ? _vm.getDateformat(row.send_time) : '--')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDateformat(row.create_time))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.lookDetail(row)}}},[_vm._v("查看详情")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }